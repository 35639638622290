import React from "react";
import { Stack, Main, Sidebar } from "@layout";
import PageTitle from "@components/PageTitle";
import Divider from "@components/Divider";
import Seo from "@widgets/Seo";
import ContactForm from "@widgets/ContactForm";
import ContactInfo from "@widgets/ContactInfo";
import Commitment from "@widgets/Commitment";

export default () => (
    <>
        <Seo title="Contact" />
        <Divider space={3} />
        <Stack>
            <Main>
                <PageTitle header="Let's Connect" />
                <Divider space={2} />
                <ContactForm />
            </Main>
            <Sidebar>
                <Commitment />
                <Divider space={3} />
                <ContactInfo />
            </Sidebar>
        </Stack>
    </>
);
